import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        flexGrow: 1,

    },
    cardActive: {
        margin: theme.spacing(2),
        backgroundColor: "#ccc"
    },
    pos: {
        marginBottom: 12,
    },

    cardButton: {
        display: "block",
        textAlign: "initial",
        width: "100%"
    },
    media: {
        height: 0,
        paddingTop: '9%', // 16:9
    },
    choices: {
        paddingBottom: theme.spacing(6)
    }
}));

export default function SampleCard(props) {
    const classes = useStyles();

    const bull = <span className={classes.bullet}>•</span>;

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={props.url}
                    style={{backgroundSize: 'contain'}}
                    title="Contemplative Reptile"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {props.text}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}