import React, { useState }  from 'react';
import './App.css';
import MathJax from "mathjax3-react";
import SampleCard from './Card'
function App() {
  let [text, setText] = useState("")
  let [textImg, setTextImg] = useState({text:"",url:""})
  let [url, setUrl] = useState("")
  const handleChange = (area) => {
    let {value} = area.target
    setText(value)
  }
  const handleChangeURL = (area) => {
    let { value } = area.target
    setUrl(value.replace(/\[img\]/gi, "").replace(/\[\/img\]/gi, ""))
  }
  const handleChangeURLString = (area) => {
    let { value } = area.target
    let data = value.split("##")
    setTextImg({
      text:data[1],
      url: data[0].replace(/\[img\]/gi, "").replace(/\[\/img\]/gi, "")
    })
  }
  return (
    <div className="App">
      
      <header className="App-header">
        <div className="mathjax">
          <p>Math Latex Validator</p>
          <textarea onChange={handleChange} col="10" row="10"></textarea>
          <MathJax.Provider
            url="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js"
            options={{
              tex: {
                inlineMath: [['$', '$'], ['\\(', '\\)']]
              }
            }}
          >
            <MathJax.Formula formula={text} />
          </MathJax.Provider>
        </div>
        <div>
          <p>Image URL Validator</p>
          <input onChange={handleChangeURL} />
          <div className="container">
            <img src={url} className="image" />
          </div>
        </div>
        <div className="card-container">
          <p>Image URL with Text Validator</p>
          
          <input onChange={handleChangeURLString} />
          <div className="">
            <SampleCard url={textImg.url} text={textImg.text}/>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
